.pt-3 {
  
    ul.small-bullet {
      list-style-type: disc;
      padding-left: 1.5em;
      padding-top: 0;
  
      li {
        margin-bottom: 0.5em;
        position: relative;
        line-height: 1.5;
        padding-left: 16px;
  
        .badge {
          &.badge-light {
            color: var(--text-color-secondary);
            font-weight: normal;
            font-size: 1rem;
            white-space: normal;
          }
        }
      }
  
      &.custom-bullet {
        list-style-type: none;
        padding-left: 0;
  
        li {
          &::before {
            content: '•';
            position: absolute;
            left: 0;
            top: 0;
            font-size: 1em;
            color: currentColor;
          }
        }
      }
    }
  }
  




  
  // .card .bg-skyblue {
  //   left: 0; 
  // }
