.chatbox-container {
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 400px;
  height: 600px;
  background-color: #EFF1F4;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: "Arial", sans-serif;
  z-index: 1000;
}
.chatbox-container .chatbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}
.chatbox-container .chatbox-header h4 {
  margin: 0;
  font-size: 17px;
  font-weight: bold;
}
.chatbox-container .chatbox-messages {
  padding: 20px;
  overflow-y: auto;
  height: calc(100% - 140px);
}
.chatbox-container .chatbox-messages .chatbox-message {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
}
.chatbox-container .chatbox-messages .chatbox-message.recruiter {
  align-self: flex-start;
}
.chatbox-container .chatbox-messages .chatbox-message.recruiter .sender-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.chatbox-container .chatbox-messages .chatbox-message.recruiter .message-wrapper {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-left: 0;
  margin-right: auto;
}
.chatbox-container .chatbox-messages .chatbox-message.recruiter .message-wrapper .message-content {
  color: #333;
}
.chatbox-container .chatbox-messages .chatbox-message.recruiter .message-wrapper .message-timestamp {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}
.chatbox-container .chatbox-messages .chatbox-message.user {
  align-self: flex-end;
  flex-direction: row-reverse;
}
.chatbox-container .chatbox-messages .chatbox-message.user .sender-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 0;
}
.chatbox-container .chatbox-messages .chatbox-message.user .message-wrapper {
  background-color: #7749F8;
  border-radius: 10px;
  padding: 10px;
  margin-right: 0;
  margin-left: auto;
  color: #fff;
}
.chatbox-container .chatbox-messages .chatbox-message.user .message-wrapper .message-content {
  color: #fff;
}
.chatbox-container .chatbox-messages .chatbox-message.user .message-wrapper .message-timestamp {
  font-size: 12px;
  color: #ccc;
  margin-top: 5px;
}
.chatbox-container .chatbox-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.chatbox-container .chatbox-input textarea {
  flex-grow: 1;
  resize: none;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 32px;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  height: 43px;
  box-sizing: border-box;
  width: 80%;
}
.chatbox-container .chatbox-input textarea::-moz-placeholder {
  color: #999;
  font-style: italic;
}
.chatbox-container .chatbox-input textarea::placeholder {
  color: #999;
  font-style: italic;
}
.chatbox-container .chatbox-input .send-button {
  color: var(--primary-color) !important;
  padding: 10px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  margin-left: 10px;
}
.chatbox-container .chatbox-input .send-button:hover {
  color: var(--primary-color-hover) !important;
}/*# sourceMappingURL=ChatBox.css.map */