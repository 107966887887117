// SearchBar.scss
.google-search-bar {
    max-width: 1000px; // Adjust as needed
    margin: auto; // Center the search bar horizontally

    .google-search-input {
      border-radius: 42px; // Rounded corners
      padding: 28px;
    }

    .google-search-button {
      border-radius: 42px; // Match the input's rounded corners
      margin-left: -1px; // Align the button with the input
      padding-right: 28px;


    }
  }

  .callout svg {
    font-size: 36px;
}

.icon-color {
color: #9747FF !important;
}


.bg-how-work-1 {
  border: #AFD2DC 1px solid !important;
}

.bg-how-work-2 {
  border: #FF6A6A 1px solid !important;
}

.bg-how-work-3 {
  border: #89E0F1 1px solid !important;
}


.bg-how-work-1, .bg-how-work-2, .bg-how-work-3 {
    p {
      font-size:30px;
      font-weight:600 !important;
    }
}

  .swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    overflow: clip;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;

    &.swiper-horizontal {
      .swiper-wrapper {
        display: flex;
        flex-wrap: nowrap; // Ensure items are in a single line horizontally
        animation: scrollAnimation 10s linear infinite; // Adjust duration (10s) as per your preference
      }

      .swiper-slide {
        flex-shrink: 0;
        width: 100%; // Adjust as per your design
        height: 100%; // Adjust as per your design
        position: relative;
        transition-property: transform;
        display: block;
      }
    }
  }

  @keyframes scrollAnimation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%); // Adjust as per your scroll direction (left to right)
    }
  }




//Badge Css

  .jbadge-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; /* Adjust the gap between badges as needed */

    .jbadge {
      background-color: #FEF1FC;
      color: var(--textColor);
      padding: 8px 16px;
      text-align: center;
      border-radius: 48px;
      border-style: solid;
      border-width: 1px;
      border-color: var(--linecolor);
      white-space: nowrap;
    }

  }





  //for Card Min height

  .min-card{
    min-height: 210px;
  }
