// .drawer-wrapper2 {
//     display: flex;
  
//     .drawer2 {
//       width: 1000px;
//       height: 100%;
//       background-color: #ffffff;
//       color: var(--text-color);
//       padding: 36px;
//       box-shadow: 0 0 4px #a5a5a5 ;
   
//       transition: transform 0.3s ease;
//       transform: translateX(1000px);
//       position: fixed;
//       right: 0;
//       top: 0;
//       z-index: 1000;
  
//       &.open {
//         transform: translateX(0);
//       }

//       .close-icon{
//         height: auto;
//         width: 24px;
//         color: var(--close-button);
//       }


         
//     }


//     .main-content {
//         flex: 1;
//         padding: 0px;
//         transition: margin-left 0.3s ease;
//         margin-left: 0;
    
//         .toggle-drawer-btn {
//           background-color: #ffffff;
//           color: white;
//           border: none;
//           padding: 0px;
//           cursor: pointer;
//         }
    
        
//       }

// }





.drawer-wrapper2 {
  display: flex;
  flex-direction: column;

  .drawer2 {
    width: 100%;
    max-width: 1000px; // Adjust max-width as needed
    height: 100%;
    background-color: #ffffff;
    color: var(--text-color);
    padding: 36px;
    box-shadow: 0 0 4px #a5a5a5;
    transition: transform 0.3s ease;
    transform: translateX(100%);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;

    &.open {
      transform: translateX(0);
    }

    .close-icon {
      height: auto;
      width: 24px;
      color: var(--close-button);
    }
  }

  .main-content {
    flex: 1;
    padding: 0;
    transition: margin-left 0.3s ease;
    margin-left: 0;

    .toggle-drawer-btn {
      background-color: #ffffff;
      color: white;
      border: none;
      padding: 0;
      cursor: pointer;
    }
  }

  // Media Query for Mobile Devices
  @media (max-width: 768px) {
    .drawer2 {
      max-width: 100%; // Adjust as needed
      transform: translateX(100%);
    }

    .main-content {
      margin-left: 0; // Ensure no extra margin on mobile
    }
  }
}
