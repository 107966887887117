.two-col-section {
  padding: 2rem 1rem;
}
.two-col-section .text-section {
  text-align: center;
}
.two-col-section .card-text {
  font-size: 1rem;
  color: #666;
}
.two-col-section .img-section img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 767.98px) {
  .two-col-section .text-section {
    text-align: center;
  }
  .two-col-section .card-text {
    font-size: 0.9rem;
  }
  .two-col-section .img-section {
    margin-top: 1rem;
    text-align: center;
  }
  .two-col-section .img-section img {
    width: 80%;
    max-width: 250px;
    margin: 0 auto;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .two-col-section .text-section {
    text-align: left;
  }
  .two-col-section .card-text {
    font-size: 1rem;
  }
  .two-col-section .img-section img {
    width: 90%;
    max-width: 350px;
  }
}
@media (min-width: 1200px) {
  .two-col-section .text-section {
    text-align: left;
  }
  .two-col-section .card-text {
    font-size: 1rem;
  }
  .two-col-section .img-section img {
    width: 100%;
    max-width: 500px;
  }
}/*# sourceMappingURL=TwoCol.css.map */