.avatar-container {
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    border-radius: 50%; /* Make it circular */
    overflow: hidden; /* Ensure image stays within border radius */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for visual effect */
  }

  .avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
  }


  .avatar-container-2 {
    width: 60px;
    height: 60px;
  }