.drawer-wrapper2 {
  display: flex;
  flex-direction: column;
}
.drawer-wrapper2 .drawer2 {
  width: 100%;
  max-width: 1000px;
  height: 100%;
  background-color: #ffffff;
  color: var(--text-color);
  padding: 36px;
  box-shadow: 0 0 4px #a5a5a5;
  transition: transform 0.3s ease;
  transform: translateX(100%);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}
.drawer-wrapper2 .drawer2.open {
  transform: translateX(0);
}
.drawer-wrapper2 .drawer2 .close-icon {
  height: auto;
  width: 24px;
  color: var(--close-button);
}
.drawer-wrapper2 .main-content {
  flex: 1;
  padding: 0;
  transition: margin-left 0.3s ease;
  margin-left: 0;
}
.drawer-wrapper2 .main-content .toggle-drawer-btn {
  background-color: #ffffff;
  color: white;
  border: none;
  padding: 0;
  cursor: pointer;
}
@media (max-width: 768px) {
  .drawer-wrapper2 .drawer2 {
    max-width: 100%;
    transform: translateX(100%);
  }
  .drawer-wrapper2 .main-content {
    margin-left: 0;
  }
}/*# sourceMappingURL=UserDataWrapper.css.map */