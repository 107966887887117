.notifications-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.notifications-list li {
  padding: 16px;
  border-bottom: 1px solid #f3f3f3;
}
.notifications-list li:last-child {
  border-bottom: none;
}
.notifications-list .notification-heading {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}
.notifications-list .notification-content {
  font-size: 14px;
  color: #666;
}
.notifications-list .notification-link {
  font-size: 14px;
  color: #337ab7;
  text-decoration: none;
}
.notifications-list .notification-link:hover {
  color: #23527c;
}/*# sourceMappingURL=NotificationsList.css.map */