/* Imported Fonts */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@100;200;400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap');

/* Root Variables */
:root {
  /* Primary colors */
  --primary-color: #7749F8;
  --secondary-color: #6D7178;
  --primary-color-hover: #5524dd;

  /* Background colors */
  --body-bg-color: #F5F7FB;
  --footer-bg-color: #f8f9fa;

  /* Text colors */
  --text-color: #212529;
  --heading-color: #212529;
  --link-color: #7749F8;

  /* Button colors */
  --btn-primary-color: #7749F8;
  --btn-secondary-color: #16B296;
  --close-button: #B9A4F3;
  --btn-orange:#F04F2C;

  /* Border colors */
  --border-color: #bbc1cb;
  --form-border-color: #D8E0ED;
  --border-radius: 0.25rem;
  --linecolor: #D873CE;
  --border-forms:#D8E0ED ;



  /* Linear colors */
  --body-bg-1: #F4F2FC;
  --body-bg-2: #ffffff;

  /* */
  --text-light-custom: #ababab;

  --landig-page-two-col-bg :#E9F1FB;

}

/* General Body Styling */
html, body {
  height: 100%;
  margin: 0;
  background-color: var(--body-bg-color) !important;
  color: var(--text-color) !important;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'DM Sans', sans-serif !important;
}

.text-color{
  color: var(--text-color);
}


h1, h2, h3, h4, h5, h6, p, a, button, input, textarea {
  font-family: 'DM Sans', sans-serif !important;
}

/* Navbar and Footer */
.footer {
  background-color: var(--footer-bg-color);
}

/* Linear gradient background */
.vertical-gradient {
  background: linear-gradient(to bottom, var(--body-bg-1), var(--body-bg-2));
}



/* Text Colors */
.primary-text{
  color: var(--link-color) !important;
}

.text-primary{
  color: var(--link-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.text-dark {
  color: var(--text-color) !important;
}

.text-light-custom {
  color: var(--text-light-custom) !important;
}



/* Home Page Custom Background Colors */
.bg-skyblue {
  background-color: var(--body-bg-color) !important;
}

.bg-how-work-1{
  background-color: #EFF6F8 !important;
  border: #AFD2DC 2px solid !important;
}


.bg-how-work-2{
  background-color: #FFF6F6 !important;
  border: #FF6A6A 2px solid !important;
}

.bg-how-work-3{
  background-color: #F6F8FF !important;
  border: #89E0F1 2px solid !important;
}






.bg-lipink {
  background-color: #EFF6F8 !important;
}

.bg-liyellow {
  background-color: #F9D8D6 !important;
}


.bg-ligree {
  background-color: #E7D3D3 !important;
}

.bg-ixprimary {
  background-color: var(--primary-color) !important;
}

.border-color {
  color: var(--border-color) !important;
}

.border-color-2 {
  border: 1px solid #c5cedc !important;
  color: inherit !important;
}

.landig-page-two-col-bg {
  background-color: var(--landig-page-two-col-bg);
}

/* App and Content Structure */
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  flex: 1;
}

footer {
  margin-top: auto;
}

/* Card Styling */
.card {
  box-shadow: 0 1px 6px 0 rgba(96, 96, 96, 0.1);
  transition: 0.3s;
  border: none !important; /* Remove border */
  border-radius: 8px !important;
}

/* Specific Font Styles */
body {
  font-family: 'DM Sans', sans-serif !important;
}

h1 {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 700 !important;
}

p {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 400;
}

.small-bullet li::marker {
  font-size: 0.6rem;
}



.bg-header{
  background-color: #F4F2FC;
}