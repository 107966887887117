.custom-dropdown-toggle {
  background-color: white;
  border: none;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  width: 100%;
  cursor: pointer;
}

.custom-dropdown-toggle:hover {
  background-color: #fff;
}

.dropdown-menu.show {
  display: contents;
}

.icon-size-sucessapplied {
  width: auto;
  height: 56px;
  color: #069245;
  margin-right: 18px;
  align-items: center;
  display: flex;
}/*# sourceMappingURL=SucessApplied.css.map */