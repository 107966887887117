.job-card {
    padding: 4px;
  }
  
  .ml-1 {
    margin-left: 0.35rem;
  }
  
  .mr-2 {
    margin-right: 0.5rem;
  }
  
  .job-card .small {
    font-size: 0.875rem;
  }
  
  .job-card .font-weight-bold {
    font-weight: medium;
  }
  
  .job-card .font-italic {
    font-style: italic;
  }
  
  .job-card .text-secondary {
    color: #6c757d;
  }
  
  
  .job-card .card-title {
    font-size: 1rem; /* 16px */
  }
  
  .job-card .text-muted {
    color: #6c757d;
  }
  
  .icon-size {
    width: auto;
    height: 1.5rem; 
    min-width: auto;
    min-height: 18px;
    color: #BFBFBF;
  }


  .icon-size-filled {
    width: auto;
    height: 1.5rem; 
    min-width: auto;
    min-height: 18px;
    color: var(--primary-color) !important;
  }

  
  
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  
  .mb-md-0 {
    margin-bottom: 0;
  }
  

  .margin-right-custom{
    margin-right: 1.5rem;
  }




