.login-search-bar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 5px;
  border: solid 1px #D9D9D9;
  overflow: hidden;
}
.login-search-bar .login-search-input,
.login-search-bar .form-control {
  padding: 10px 0px;
  font-size: 14px;
  border: none;
  outline: none;
}
.login-search-bar .login-search-input {
  border-right: solid 1px #D9D9D9;
}
.login-search-bar .login-search-button {
  padding: 10px 15px;
  background-color: #7749F8;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}/*# sourceMappingURL=SearchBar.css.map */