.custom-tabs .nav-link {
  color: #6c757d; /* Bootstrap's text-secondary color */
  border: none; /* Remove border */
  background-color: transparent; /* Remove button background */
  padding: 0.5rem 1rem; /* Adjust padding to align text */
}

.custom-tabs .nav-link.active {
  color: #7749F8; /* Custom link color */
  border: none; /* Remove active border */
  background-color: transparent; /* Ensure no background on active tab */
  font-weight: bold; /* Make active tab bold */
  border-bottom: 3px solid #7749F8; /* Add underline to the active tab */
}

.custom-tabs .nav-link:hover {
  color: #7749F8; /* Darker shade for hover effect */
  text-decoration: underline; /* Optional: Add underline on hover */
}

.custom-tabs .nav-link:focus {
  outline: none; /* Remove focus outline */
}

.margin-right-custom {
  margin-right: 30px;
}/*# sourceMappingURL=UserData.css.map */