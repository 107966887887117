.bg-white {
    background-color: #565656 !important;
}

footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .nav-link {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem; 
        padding-bottom: 0.5rem;
        color: #1D192B !important;

    }


    &:hover {
        color: #484848 !important; 
    }



    .text-start {
        color: var(--text-color) !important;
        margin-bottom: 0;
    }
}