.two-col-section {
  padding: 2rem 1rem;

  .text-section {
    text-align: center;
  }

  .card-text {
    font-size: 1rem;
    color: #666;
  }

  .img-section {
    img {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  @media (max-width: 767.98px) {
    .text-section {
      text-align: center;
    }

    .card-text {
      font-size: 0.9rem;
    }

    .img-section {
      margin-top: 1rem;
      text-align: center;

      img {
        width: 80%;
        max-width: 250px;
        margin: 0 auto;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1199.98px) {
    .text-section {
      text-align: left;
    }

    .card-text {
      font-size: 1rem;
    }

    .img-section {
      img {
        width: 90%;
        max-width: 350px;
      }
    }
  }

  @media (min-width: 1200px) {
    .text-section {
      text-align: left;
    }

    .card-text {
      font-size: 1rem;
    }

    .img-section {
      img {
        width: 100%;
        max-width: 500px;
      }
    }
  }
}