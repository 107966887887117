

  

.dropdown-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #1c1b1f;
  }


  .custom-badge {
    margin: 5px;
    padding: 16px;
    cursor: pointer;
    background-color: #FEF1FC !important; /* Transparent background */
    color: #A1559A !important; /* Secondary color for text */
    border: 1px solid #D873CE !important; /* Secondary color for border */
    border-radius: 50px; /* Rounded border */
  }

  



  .placeholder {
    font-size: 14px !important; 
    color: #C3CBDC !important; 
  }

  



  .custom-outline-secondary {
    background-color: transparent; /* Transparent background */
    color: var(--bs-secondary) !important; /* Secondary color for text */
    border: 1px solid var(--bs-secondary) !important; /* Secondary color for border */
  }









  .my-form {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    text-align: start;
  }
  
  .my-form legend {
    color: var(--text-color);
    font-size: 18px;
  }

  
  .form-group {
    margin-bottom: 20px;
  }


  .search-results {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .search-results .btn {
    margin: 5px;
  }



  .form-control-lg {
    height: 50px; /* Increased height for the input field */
  }

  
  .selected-skills {
    margin-top: 20px;
  }
  


  .selected-skills .badge {

    border-radius: 50px;
    padding: 16px;
    cursor: pointer;
    background-color: #FEF1FC !important; /* Transparent background */
    color: #A1559A !important; /* Secondary color for text */
    border: 1px solid #D873CE !important; /* Secondary color for border */
    border-radius: 50px; /* Rounded border */

  }
  


  
.form-label {
  margin-bottom: 10px;
  text-align: start;
  font-weight: 400;
  align-items: start;
}



label {
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
  color: var(--secondary-color);
}



  .form-control-close {
    background: transparent;
    cursor: pointer;
    font-size: 14px; /* Adjust the size of the close button */
    color: var(--text-color) !important;
    margin-right: 1rem;
    &:hover {
      color: #FF0000; /* Change color on hover, e.g., to red */
    }
  }


  
  .form-control-custom {
    display: block !important;
    width: 100% !important;
    padding: 0.800rem 0.75rem !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: var(--bs-body-color) !important; /* Regular text color */
    appearance: none !important;
    background-color: var(--bs-body-bg) !important;
    background-clip: padding-box !important;
    border: var(--bs-border-width) solid var(--border-forms) !important;
    border-radius: var(--bs-border-radius) !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;

    &::placeholder {
        font-size: 14px !important;
        color: #C3CBDC !important;
        font-weight: 500;
    }

    /* Date input specific placeholder color */
    /* &::-webkit-datetime-edit-text,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-year-field {
        color: #C3CBDC !important;
    } */

    /* Calendar icon styling */
    &::-webkit-calendar-picker-indicator {
        color: #C3CBDC;
        cursor: pointer;
    }

    /* Adjust for dropdowns (select elements) */
    select.form-control-custom {
        color: var(--bs-body-color) !important; /* Regular text color for dropdown */
    }

    /* Reset the color of selected option */
    select.form-control-custom option {
        color: var(--bs-body-color) !important; /* Regular text color for options */
    }

    select.form-control-custom option[value=""] {
        color: #C3CBDC !important; /* Placeholder color for empty value option */
    }

    /* If you want to ensure the placeholder is not selectable and hidden after selection */
    select.form-control-custom:invalid {
        color: #C3CBDC !important;
    }

    select.form-control-custom:not(:invalid) {
        color: var(--bs-body-color) !important; /* Regular text color when a value is selected */
    }

    
    input[type="date"].form-control-custom {
        position: relative;
        padding-right: 40px;
    }

    input[type="date"].form-control-custom::-webkit-calendar-picker-indicator {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--bs-body-color);
        cursor: pointer;
        height: 80%;
        z-index: 1;
        opacity: 0.8;
    }
}


.form-control-custom[type="date"]::-moz-placeholder {
    color: #C3CBDC !important;
}


.form-control-custom[type="date"]::-ms-input-placeholder {
    color: #C3CBDC !important;
}










/* Model css */


.modal-dialog {
  max-width: 800px !important;
  width: 60% !important; 
}

.modal-content {
  min-height: 400px !important; 
  height: auto !important; 
}

.modal-body {
  padding: 30px !important;
}


/* Upload Resume Modal css */

.resume-container {
  border: 1.5px dashed #448CFF !important;
  background-color: #FAFCFE !important;
  padding: 66px !important;
  text-align: center !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;

border-style: dashed !important;
border-radius: 8px !important;
background-clip: padding-box !important;
}

.resume-icon{
  max-width: 76px;
  max-height: auto;
}

.form-header-icon{
  background-color: #ffffff;
  padding: 12px;
  color: var(--link-color);
  border-radius: 50%;
  margin-right: 16px;
  border: #7749F8 1px solid;
}


.form-header-icon:hover{
  background-color: #e8f1f4;
  padding: 12px;
  color: var(--link-color);
  border-radius: 50%;
  margin-right: 16px;
  border: #7749F8 1px solid;
}




/* buttons */

  
/* Button Styling */
.btn-primary {
  background-color: var(--btn-primary-color) !important;
  border-color: var(--btn-primary-color) !important;
  color: #ffffff !important;
  
}


.btn-primary:hover {
  background-color:var(--primary-color-hover) !important;
  border-color:var(--primary-color-hover) !important;
  color: #ffffff !important;
}


.btn-outline-primary {
  background-color: transparent !important;
  border-color: var(--btn-primary-color) !important;
  color: var(--btn-primary-color) !important;
}

.btn-outline-primary:hover {
  background-color: var(--btn-primary-color) !important;
  color: #ffffff !important;
}


.btn-secondary {
  background-color: var(--btn-secondary-color) !important;
  border-color: var(--btn-secondary-color) !important;
}


.btn-orange {
  background-color: var(--btn-orange) !important;
  border-color: var(--btn-orange) !important;
  color: #ffffff !important;
}


.profile-btn {
  border-radius:  0 0 8px 8px !important;
}




.btn-forms-primary{
  background-color: var(--btn-primary-color) !important;
  border-color: var(--btn-primary-color) !important;
  color: #ffffff !important;
  padding: 16px 24px !important;
  font-weight: 500 !important;
  margin-top: 24px !important;
}

.btn-forms-primary:hover{
  background-color:var(--primary-color-hover) !important;
  border-color:var(--primary-color-hover) !important;
  color: #ffffff !important;
  padding: 16px 24px !important;
  font-weight: 500 !important;
  margin-top: 24px !important;
}



.btn-forms-outline{
  background-color: transparent !important;
  border-color: var(--btn-primary-color) !important;
  color: var(--btn-primary-color) !important;
  padding: 16px 24px !important;
  font-weight: 500 !important;
  margin-top: 24px !important;
  margin-right: 24px !important;
}

.btn-forms-outline:hover{
  background-color: var(--btn-primary-color) !important;
  color: #ffffff !important;
  padding: 16px 24px !important;
  font-weight: 500 !important;
  margin-top: 24px !important;
  margin-right: 24px !important;
}




.headericons {
  display: flex;
  align-items: center;
  padding: 16px 0px;
}

.message-count-badge {
  background-color: var(--primary-color);
  color: white;
  padding: 4px 6px;
  font-size: 10px;
  border-radius: 50%;
  line-height: 1;
}



.service-header-icons {
  cursor: pointer;
  padding: 14px;
  width: auto;
  color: #D9D9D9 !important;

  &:hover{
    color: var(--primary-color) !important;
  }

}






.close-line-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  
}

.close-line-container hr {
  flex-grow: 1;
  border: none;
  border-top: 1px solid #ccc;
}

.close-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 0 8px;
}









