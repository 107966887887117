

// Google Button css


.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;

  padding: 18px 18px 18px 52px;
  border: none;
  border-radius: 36px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

  color: var(--text-color);
  font-size: 18px;
  font-weight: 600;


  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 16px 22px;


  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}

body {
  background: #f5f7fb;
  font-family: DM Sans, sans-serif;
  font-size: 1rem;
}



.drawer-wrapper {
  display: flex;
  flex-direction: column;

  .drawer {
    width: 100%;
    height: 100%;
    max-width: 600px; // Adjust to your preference
    background-color: #ffffff;
    color: var(--text-color);
    box-shadow: -4px 0 18px 0 #00000014;
    transition: transform 0.3s ease;
    transform: translateX(100%);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;

    &.open {
      transform: translateX(0);
    }
  }

  .main-content {
    flex: 1;
    padding: 0;
    transition: margin-left 0.3s ease;
    margin-left: 0;

    .toggle-drawer-btn {
      background-color: #333;
      color: white;
      border: none;
      padding: 10px;
      cursor: pointer;
    }
  }

  // Media Query for Mobile Devices
  @media (max-width: 768px) {
    .drawer {
      max-width: 100%; // Adjust as needed
      transform: translateX(100%);
    }

    .main-content {
      margin-left: 0;
    }
  }
}






.drawer-wrapper-sidemenu {
  display: flex;
  flex-direction: column;

  .drawer {
    width: 100%;
    height: 100%;
    max-width: 450px; // Adjust to your preference
    background-color: #ffffff;
    color: var(--text-color);
    box-shadow: -4px 0 18px 0 rgba(0, 0, 0, 0.0784313725);
    transition: transform 0.3s ease;
    transform: translateX(100%);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;

    &.open {
      transform: translateX(0);
    }
  }

  .main-content {
    flex: 1;
    padding: 0;
    transition: margin-left 0.3s ease;
    margin-left: 0;

    .toggle-drawer-btn {
      background-color: #333;
      color: white;
      border: none;
      padding: 10px;
      cursor: pointer;
    }
  }

  // Media Query for Mobile Devices
  @media (max-width: 768px) {
    .drawer {
      max-width: 100%; // Adjust as needed
      transform: translateX(100%);
    }

    .main-content {
      margin-left: 0;
    }
  }
}







.drawer-wrapper-savedjobs {
  display: flex;
  flex-direction: column;

  .drawer {
    width: 100%;
    height: 100%;
    max-width: 800px; // Adjust to your preference
    background-color: #ffffff;
    color: var(--text-color);
    box-shadow: -4px 0 18px 0 rgba(0, 0, 0, 0.0784313725);
    transition: transform 0.3s ease;
    transform: translateX(100%);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;

    &.open {
      transform: translateX(0);
    }
  }

  .main-content {
    flex: 1;
    padding: 0;
    transition: margin-left 0.3s ease;
    margin-left: 0;

    .toggle-drawer-btn {
      background-color: #333;
      color: white;
      border: none;
      padding: 10px;
      cursor: pointer;
    }
  }

  // Media Query for Mobile Devices
  @media (max-width: 768px) {
    .drawer {
      max-width: 100%; // Adjust as needed
      transform: translateX(100%);
    }

    .main-content {
      margin-left: 0;
    }
  }
}










// All Devices heights


/* Mobile devices (480px and up) */
@media only screen and (min-width: 480px) {
  .d-flex.js-lg-link {
    margin: 0px 0 0 15px;
    width: 100%;
    justify-content: inherit;
  }
  .js-head-frm input.form-control.rounded-start {
    min-width: 150px;
  }
  .js-pg-hg {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
    min-height: 100vh;
  }
  .fr-toggle {
    min-width: 125px;
    background: #fff;
  }
}

/* Tablet devices (768px and up) */
@media only screen and (min-width: 768px) {
  .d-flex.js-lg-link {
    margin: 0px 0 0 20px;
    width: 100%;
    justify-content: inherit;
  }
  .js-head-frm input.form-control.rounded-start {
    min-width: 180px;
  }
  .js-pg-hg {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    min-height: 100vh;
  }
  .fr-toggle {
    min-width: 150px;
    background: #fff;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .d-flex.js-lg-link {
    margin: 0px 0 0 30px;
    width: 100%;
    justify-content: inherit;
  }
  .js-head-frm input.form-control.rounded-start {
    min-width: 200px;
  }
  .js-pg-hg {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
    min-height: 100vh;
  }
  .fr-toggle {
    min-width: 175px;
    background: #fff;
  }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .js-head-frm input.form-control.rounded-start {
    min-width: 300px;
  }
  .js-pg-hg {
    padding-top: 40px !important;
    padding-bottom:40px !important;
    min-height: 100vh;
  }
  .fr-toggle {
    min-width: 200px;
    background: #fff;
  }
  .d-flex.js-lg-link {
    margin: 0px 0 0 30px;
    width: auto;
    justify-content: inherit;
  }
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
  line-height: 30px;
}

// Header Sub Height

.header-sub-height {
  padding-bottom: 32px;
}

// Responsive Design

/* Small devices (mobiles, 576px and up) */
@media only screen and (min-width: 576px) {
  .header-sub-height {
    padding-bottom: 24px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .header-sub-height {
    padding-bottom: 30px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .header-sub-height {
    padding-bottom: 36px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .header-sub-height {
    padding-bottom: 50px;
  }
}
