

// .message-card {
//   background-color: #fff;
//   margin-bottom: 10px;
//   padding: 0px;
// }

// .message-header {
//   display: flex;
//   justify-content: flex-start;
//   align-items: flex-start; 
// }

// .profile-icon,
// .profile-initials {
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   object-fit: cover;
//   display: inline-block;
//   vertical-align: top;
//   margin-right: 10px; 
// }

// .message-info {
//   display: flex;
//   flex-direction: column; 
//   align-items: flex-start;
// }

// .sender-name {
//   font-weight: bold;
//   font-size: 0.9rem;
//   margin-bottom: 2px; 
// }

// .company-name {
//   font-size: 0.8rem;
//   color: #666;
//   margin-bottom: 4px; 
// }

// .message-content {
//   font-size: 0.8rem;
//   color: #333;
//   text-align: left; 
// }

// .message-timestamp {
//   font-size: 0.75rem;
//   color: #999;
//   display: flex;
//   align-items: center; 
//   justify-content: space-between; 
// }

// .badge {
//   margin-left: auto;
// }








.unread-messages-container {

  .message-card {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 15px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .message-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .profile-icon,
  .profile-initials {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
  }

  .profile-initials {
    background-color: #337ab7;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
  }

  .message-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
  }

  .sender-name {
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 2px;
  }

  .company-name {
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 4px;
  }

  .message-content {
    font-size: 0.8rem;
    color: #333;
    text-align: left;
  }

  .message-timestamp {
    font-size: 0.75rem;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .badge {
    margin-left: auto !important;
    background-color: #7749F8 !important;
    color: #fff !important;
    padding: 6px 8px !important;
    border-radius: 50% !important;
    font-size: 0.75rem !important;
    margin-left: 8px !important;
  }

  .message-count-badge {
    background-color: var(--primary-color) !important;
  }

  hr {
    border-top: 1px solid #ccc;
    margin: 10px 0;
  }
}