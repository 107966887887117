@charset "UTF-8";
.pt-3 ul.small-bullet {
  list-style-type: disc;
  padding-left: 1.5em;
  padding-top: 0;
}
.pt-3 ul.small-bullet li {
  margin-bottom: 0.5em;
  position: relative;
  line-height: 1.5;
  padding-left: 16px;
}
.pt-3 ul.small-bullet li .badge.badge-light {
  color: var(--text-color-secondary);
  font-weight: normal;
  font-size: 1rem;
  white-space: normal;
}
.pt-3 ul.small-bullet.custom-bullet {
  list-style-type: none;
  padding-left: 0;
}
.pt-3 ul.small-bullet.custom-bullet li::before {
  content: "•";
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1em;
  color: currentColor;
}/*# sourceMappingURL=JobDetails.css.map */