.google-search-bar {
  max-width: 1000px;
  margin: auto;
}
.google-search-bar .google-search-input {
  border-radius: 42px;
  padding: 28px;
}
.google-search-bar .google-search-button {
  border-radius: 42px;
  margin-left: -1px;
  padding-right: 28px;
}

.callout svg {
  font-size: 36px;
}

.icon-color {
  color: #9747FF !important;
}

.bg-how-work-1 {
  border: #AFD2DC 1px solid !important;
}

.bg-how-work-2 {
  border: #FF6A6A 1px solid !important;
}

.bg-how-work-3 {
  border: #89E0F1 1px solid !important;
}

.bg-how-work-1 p, .bg-how-work-2 p, .bg-how-work-3 p {
  font-size: 30px;
  font-weight: 600 !important;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  overflow: clip;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
}
.swiper.swiper-horizontal .swiper-wrapper {
  display: flex;
  flex-wrap: nowrap;
  animation: scrollAnimation 10s linear infinite;
}
.swiper.swiper-horizontal .swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.jbadge-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Adjust the gap between badges as needed */
}
.jbadge-container .jbadge {
  background-color: #FEF1FC;
  color: var(--textColor);
  padding: 8px 16px;
  text-align: center;
  border-radius: 48px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--linecolor);
  white-space: nowrap;
}

.min-card {
  min-height: 210px;
}/*# sourceMappingURL=LandingPage.css.map */