// .progress-bar-container {
//   display: flex;
//   flex-direction: column;
//   align-content: start;
//   width: 100%; /* Adjust width as needed */
//   margin: 10px;
//   text-align: start;
//   position: relative;

//   .progress-step {
//     display: flex;
//     align-items: center;
//     margin-bottom: 5px;
//     position: relative;

//     label{
//       color: var(--text-color);
//       padding-left: 8px;
//       align-items: center;
//     }

//   }

//   .progress-step input[type='checkbox'] {
//     margin-right: 5px;
//     appearance: none;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     width: 24px;
//     height: 24px;
//     border-radius: 50%;
//     border: 2px solid #7749F8;
//     outline: none;
//     cursor: pointer;
//     transition: background-color 0.3s ease;

//   }


//   input[type='checkbox']:checked {
//     background-color: #7749F8;
//   }


//   .progress-bar {
//     background-color: lightgreen;
//     width: 100%;
//     transition: height 0.3s ease-in-out;
//   }



// .navigation_menu {
//   font-family: 'Helvetica';
//   font-size: 14px;
//   margin-top: 5px;
// }

// .navigation_tabs {
//   counter-reset: step;
//   position: relative;
//   padding-left: 45px;
//   list-style: none;
// }

// .navigation_tabs::before {
//     display: inline-block;
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 18px;
//     width: 10px;
//     height: 100%;
//     border-left: 2px solid #CCC;
//   }

// .navigation_menu ul {
//     list-style-type: none;
//     padding-right: 0;
//     margin-top: 0;
//     margin-left: 0;
//     margin-right: 0px;
//     margin-bottom: 0;
// }

// .navigation_menu li {
//     position: relative;
//     counter-increment: list;
// }

// .navigation_menu li:before {
//       display: inline-block;
//       content: '';
//       position: absolute;
//       left: -30px;
//       height: 100%;
//       width: 10px;
//     }

// .navigation_menu li:after {
//       content: "";
//       display: inline-block;
//       position: absolute;
//       top: 0;
//       left: -39px;
//       width: 25px;
//       height: 25px;
//       line-height: 26px;
//       border: 1px solid #DDD;
//       border-radius: 50%;
//       background-color: #FFF;
//       display: block;
//       text-align: center;
//       margin: 0 auto 10px auto;
//   }

// .navigation_menu li:not(:last-child) {
//       padding-bottom: 25px;
// }

// .navigation_menu li.tab_inactive:before {
//         border-left: 3px solid #7749F8;
//         margin-left: 3px;
//  }

// .navigation_menu li.tab_active:after {
//   border: 1px solid #7749F8;
// }

// .navigation_menu li.tab_inactive:after {
//         content: "\2022";
//         font-size: 56px;
//         color: #7749F8;
//         text-align: center;
//         vertical-align: middle;
//         border: 1px solid #7749F8;
//         background-color: #f9f9f9;

// }

// .navigation_tabs li a,
// .navigation_tabs li a {
//   display: block;
//   padding-top: 8px;
//   text-decoration: none;
//   color: #000;
// }

// .navigation_tabs li.tab_inactive a{
//   color: green;
// }

// .navigation_tabs li.tab_disabled a {
//   pointer-events: none;
//   cursor: default;
//   text-decoration: none;
// }

// .navigation_tabs li.tab_active a:hover,
// .navigation_tabs li.tab_inactive a:hover {
//   font-weight: bold;
// }

// }





.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-content: start;
  width: 100%;
  margin: 10px;
  text-align: start;
  position: relative;
}

.navigation_menu {
  position: relative;
  font-family: 'Helvetica';
  font-size: 14px;
}

.navigation_tabs {
  position: relative;
  list-style: none;
  padding-left: 40px;
  counter-reset: step;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 52px;
    width: 2px;
    height: 85%;
    background-color: #7749F8;
    z-index: 1;
    transform: translateY(-50%);
    transition: height 0.3s ease-in-out;
  }
}

.progress-bar-container {
  .progress-bar {
    margin-left: 30px;
    width: 10px;
    background-color: #e0e0e0;
    position: relative;

    .progress-bar-fill {
      background-color: #7749F8;
      width: 100%;
      transition: height 0.3s ease-in-out;
    }
  }
}

.progress-step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 12px;
    left: 12px;
    width: 2px;
    height: 100%;
    background-color: #7749F8;
    z-index: -1;
    transition: height 0.3s ease-in-out;
  }

  & + .progress-step::before {
    top: 0;
    height: 100%;
  }
}

.circle {
  margin-right: 10px;
  position: relative;
  z-index: 2;
  background-color: #fff;
  border-radius: 50%;
  padding: 0 px;
  height: 24px;
}

.circle input[type='checkbox'] {
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #7749F8;
  cursor: pointer;
  position: relative;
  z-index: 1;

  &:checked {
    background-color: #7749F8;
  }
}

.tab_active {
  label {
    font-weight: bold;
    color: var(--link-color) !important;
  }
}

.tab_inactive {
  label {
    color: #6c757d;
  }
}
