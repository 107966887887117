.drawer {
  width: 763px; /* Adjust this value to your desired width */
}

.drawer-wrapper {
  display: flex;
}

.drawer {
  width: 300px;
  background-color: white;
  border-right: 1px solid #ccc;
  height: 100vh; /* Full height */
  overflow-y: auto; /* Add vertical scrollbar */
  transition: transform 0.3s ease;
}

.drawer.open {
  transform: translateX(0);
}

.main-content {
  flex-grow: 1;
  padding: 20px;
}

.drawer::-webkit-scrollbar {
  width: 8px;
}

.drawer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.drawer::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.close-line-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.close-line-container hr {
  flex-grow: 1;
  border: none;
  border-top: 1px solid #ccc;
}

.close-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 0 8px;
}/*# sourceMappingURL=SavedJobsWrapper.css.map */