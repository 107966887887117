.login-search-bar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 5px;
    border: solid 1px #D9D9D9;
    overflow: hidden;
  
    .login-search-input,
    .form-control {
      padding: 10px 0px;
      font-size: 14px;
      border: none;
      outline: none;
    }
  
    .login-search-input {
      border-right: solid 1px #D9D9D9;
    }
  
    .login-search-button {
      padding: 10px 15px;
      background-color: #7749F8;
      color: #fff;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }




