

.chatbox-container {
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 400px;
  height: 600px;
  background-color: #EFF1F4;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: 'Arial', sans-serif;
  z-index: 1000;

  .chatbox-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;

    h4 {
      margin: 0;
      font-size: 17px;
      font-weight: bold;
    }

  }

  .chatbox-messages {
    padding: 20px;
    overflow-y: auto;
    height: calc(100% - 140px);

    .chatbox-message {
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;

      &.recruiter {
        align-self: flex-start;

        .sender-image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .message-wrapper {
          background-color: #fff;
          border-radius: 10px;
          padding: 10px;
          margin-left: 0;
          margin-right: auto;

          .message-content {
            color: #333;
          }

          .message-timestamp {
            font-size: 12px;
            color: #999;
            margin-top: 5px;
          }
        }
      }

      &.user {
        align-self: flex-end;
        flex-direction: row-reverse;

        .sender-image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-left: 10px;
          margin-right: 0;
        }

        .message-wrapper {
          background-color: #7749F8;
          border-radius: 10px;
          padding: 10px;
          margin-right: 0;
          margin-left: auto;
          color: #fff;

          .message-content {
            color: #fff;
          }

          .message-timestamp {
            font-size: 12px;
            color: #ccc;
            margin-top: 5px;
          }
        }
      }
    }
  }

  .chatbox-input {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ccc;
    position: absolute;
    bottom: 0;
    width: 100%;



    textarea {
      flex-grow: 1;
      resize: none;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 32px; // Changed border radius
      font-family: 'Arial', sans-serif;
      font-size: 14px;
      height: 43px;
      box-sizing: border-box;
      width: 80%;


      &::placeholder {
        color: #999;
        font-style: italic;
      }
    }

    .send-button {
      // background-color: #7749F8;
      color: var(--primary-color) !important;
      padding: 10px;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      font-size: 24px;
      margin-left: 10px;

      &:hover {
        color: var(--primary-color-hover) !important;
      }
    }
  }
}
