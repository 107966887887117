

.avatar-container-custom {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    text-align: center;
    background-color: #F7F6C2;
    line-height: 45px;
}
  
  .avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
  }

  .avatar-text {
    color: white;
    font-size: 15px;
    font-weight: bold;
  }







  .drawer-wrapper {
    display: flex;
  }
  
  .drawer {
    width: 250px;
    background-color: #f8f9fa;
    box-shadow: none;
    border-right: 1px solid #ddd;
    position: fixed;
    top: 0;
    bottom: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .drawer.open {
    transform: translateX(0);
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 250px;
    transition: margin-left 0.3s ease-in-out;
  }
  
  .main-content > .btn.avatar-container-custom {
    cursor: pointer;
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  
  .close-icon {
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .avatar-container {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }
  
  .user-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .avatar-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    font-weight: bold;
  }
  
  .user-name {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .user-role {
    font-size: 0.875rem;
    color: #666;
  }
  
  .menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-item {
    margin: 10px 0;
  }
  
  .menu-item > a {
    text-decoration: none;
    color: var(--secondary-color) !important;
    display: flex;
    align-items: center;
  }
  
  .menu-icon {
    font-size: 1.2rem;
    color: #666;
  }
  
  .menu-text {
    font-size: 1rem;
    font-weight: 500;
  }
  