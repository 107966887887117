.headericons {
  display: flex;
  align-items: center;
  padding: 16px 0px;
}

.message-count-badge {
  background-color: var(--primary-color);
  color: white;
  padding: 4px 6px;
  font-size: 10px;
  border-radius: 50%;
  line-height: 1;
  top: 11px !important;
}