.notifications-list {
    list-style: none;
    padding: 0;
    margin: 0;
  
    li {
      padding: 16px;
      border-bottom: 1px solid #f3f3f3;
  
      &:last-child {
        border-bottom: none;
      }
    }
  
    .notification-heading {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  
    .notification-content {
      font-size: 14px;
      color: #666;
    }
  
    .notification-link {
      font-size: 14px;
      color: #337ab7;
      text-decoration: none;
  
      &:hover {
        color: #23527c;
      }
    }
  }