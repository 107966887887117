.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-content: start;
  width: 100%;
  margin: 10px;
  text-align: start;
  position: relative;
}

.navigation_menu {

}

.navigation_tabs {
  position: relative;
  list-style: none;
  padding-left: 40px;
  counter-reset: step;
}
.navigation_tabs::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 30px;
  width: 2px;
  height: 85%;
  background-color: #7749F8;
  z-index: 1;
  transform: translateY(-50%);
  transition: height 0.3s ease-in-out;
}

.progress-bar-container .progress-bar {
  margin-left: 30px;
  width: 10px;
  background-color: #e0e0e0;
  position: relative;
}
.progress-bar-container .progress-bar .progress-bar-fill {
  background-color: #7749F8;
  width: 100%;
  transition: height 0.3s ease-in-out;
}

.progress-step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}
.progress-step::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 12px;
  width: 2px;
  height: 100%;
  background-color: #7749F8;
  z-index: -1;
  transition: height 0.3s ease-in-out;
}
.progress-step + .progress-step::before {
  top: 0;
  height: 100%;
}

.circle {
  margin-right: 0;
  position: relative;
  z-index: 2;
  background-color: #fff;
  border-radius: 50%;
  padding: 0;
  height: 24px;
  position: relative;
  left: -21px;
  top: -2px;
}

.circle input[type=checkbox] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #7749F8;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.circle input[type=checkbox]:checked {
  background-color: #7749F8;
}

.tab_active label {
  font-weight:500;
  color: var(--link-color) !important;
}

.tab_inactive label {
  color: #6c757d;
}/*# sourceMappingURL=VerticalProgressBar.css.map */