.unread-messages-container .message-card {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
}
.unread-messages-container .message-card:hover {
  background-color: #f5f5f5;
}
.unread-messages-container .message-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.unread-messages-container .profile-icon,
.unread-messages-container .profile-initials {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.unread-messages-container .profile-initials {
  background-color: #337ab7;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
}
.unread-messages-container .message-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}
.unread-messages-container .sender-name {
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 2px;
}
.unread-messages-container .company-name {
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 4px;
}
.unread-messages-container .message-content {
  font-size: 0.8rem;
  color: #333;
  text-align: left;
}
.unread-messages-container .message-timestamp {
  font-size: 0.75rem;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.unread-messages-container .badge {
  margin-left: auto !important;
  background-color: #7749F8 !important;
  color: #fff !important;
  padding: 6px 8px !important;
  border-radius: 50% !important;
  font-size: 0.75rem !important;
  margin-left: 8px !important;
}
.unread-messages-container .message-count-badge {
  background-color: var(--primary-color) !important;
}
.unread-messages-container hr {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}/*# sourceMappingURL=UnreadMessages.css.map */